<template>
        <div class="row justify-content-center">
            <div class="col-lg-5 col-md-7">
                <div class="card bg-secondary border-4 login-box">
                    <div class="card-body px-lg-5 py-lg-5">
                        <div class="mb-4">
                            <img src="/img/brand/aybitpos_logo.png" alt="" srcset="">
                        </div>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <i class="fa fa-key"></i>
                                </span>
                            </div>
                            <input @keyup.enter="girisYap" id="musteri-no" class="form-control" placeholder="Müşteri No" type="number" v-model="musteriNo">
                        </div>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <i class="fa fa-user"></i>
                                </span>
                            </div>
                            <input @keyup.enter="girisYap" id="kullanici-adi" class="form-control" type="text" placeholder="Kullanıcı Adı" v-model="kullaniciAdi">
                        </div>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <i class="fa fa-lock"></i>
                                </span>
                            </div>
                            <input @keyup.enter="girisYap" id="parola" class="form-control" type="password" placeholder="Parola" v-model="parola">
                        </div>
                        <base-button @click="girisYap" block type="success" class="my-4">
                            <i class="fas fa-sign-in-alt"></i> Giriş Yap
                        </base-button>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>

import api from '@/api/api'

import swal from 'sweetalert'
import moment from 'moment'

  export default {
    name: 'GirisYap',
    data() {
      return {
        musteriNo: '',
        kullaniciAdi: '',
        parola: '',
        turID: 6
      }
    },
    methods: {
        async sistemKontrolu() {

            const form = new FormData()

            form.append('Kullanicilar[0][TurID]', this.turID)
            form.append('Kullanicilar[0][MusteriNo]', this.musteriNo)

            const cevap = await (await api.sistemKontrolEt(form)).json()

            return cevap;
        },
        async girisYap() {

            const { musteriNo, kullaniciAdi, parola } = this;

            if (!musteriNo || !kullaniciAdi || !parola) {
                swal('UYARI', 'müşteri No, kullanıcı Adı ve parola zorunludur', 'warning')

                return;
            }

            const sistemCevap = await this.sistemKontrolu();

            const { aybitPos } = sistemCevap;

            const { durum, aybitPosSuresi } = aybitPos;

            if (musteriNo != 10014 && !durum) {

                const content = document.createElement('div');

                const sonTarih = moment(aybitPosSuresi).format('DD.MM.YYYY')

                content.innerHTML = `
                    <p>Kullanım süreniz ${sonTarih} tarihinde dolmuştur.</p>
                    <p>Destek için <a href="tel:+902622555212">0262 255 52 12</a> nolu telefondan bize ulaşabilirsiniz.</p>
                `
                swal({
                    title: 'UYARI',
                    content,
                    icon: 'warning'
                })

                return;
            }

            const loginData = new FormData()

            loginData.append('MusteriNo', this.musteriNo)
            loginData.append('kullanici-adi', this.kullaniciAdi)
            loginData.append('parola', this.parola)

            api
             .girisYap(loginData)
             .then(resp => resp.json())
             .then(data => {
                 const girisBilgileri = data[0]

                 if (girisBilgileri.length > 0) {
                     const obj = girisBilgileri[0]
                     localStorage.setItem('yonetimPosPanel', JSON.stringify(obj))
                     this.$router.push('/dashboard')
                 } else {
                     swal("UYARI", "Kullanıcı bilgileri hatalı, lütfen tekrar deneyin", "warning")
                 }

             })
        }
    }
  }
</script>

<style>
body {
    background-color: #ffffff;
}

.login-box {
    border: 1px solid #008cba;
}
</style>
